import { graphql } from 'gatsby'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import BlueprintLayout from '../../layouts/blueprint'

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  h1 {
    text-transform: uppercase;
  }
`

const LabPage = () => {
  return <BlueprintLayout>
    <Content>
      <h1><Trans>The Lab</Trans></h1>
      <p>
        <Trans>This page is still under construction.</Trans>
      </p>
    </Content>
  </BlueprintLayout>
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default LabPage
